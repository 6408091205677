
const startupTasks = requireAllStartupTasks();

function requireAllStartupTasks () {
    // `require.context` allows us to bundle all files in a directory that match a regex.
    // https://webpack.js.org/guides/dependency-management/#requirecontext
    const r = require.context('./startup-tasks', /* include subdirectories */ false, /\.js$/);
    return r.keys().map(id => r(id).default);
}

export default async function startup () {
    try {
        await Promise.all(
            startupTasks.map(async (task) => task()),
        );
    }
    catch (err) {
        console.error('error in app startup', err);
    }
}
