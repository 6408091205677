
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Vue from 'vue';
import VueI18Next from '@panter/vue-i18next';
import RSCDBackend from '../../../lib/rs-cd-backend';
import { browserDetector } from '../../../lib/vanity-locale';

import testResources from './test-resources';

Vue.use(VueI18Next);

const
    initOpts = {"preload":["en"],"debug":false,"defaultNS":"global","fallbackLng":["en"],"returnNull":true,"returnObjects":true,"ns":["global","links","buttons","share"],"detection":{"lookupQuerystring":"locale","order":["querystring","navigator","header","vanity"],"caches":false}},
    { enable: enableVanityLocale } = {"_public":true,"enable":false,"defaultLocale":"en-US","pairs":[{"pattern":"(fr\\\\.|-fr)","locale":"fr-CA"},{"pattern":"(\\\\.ca|-ca)","locale":"en-CA"}]};

const languageDetector = new LanguageDetector();
if (enableVanityLocale) {
    languageDetector.addDetector(browserDetector);
}

export const initPromise = i18next
.use(RSCDBackend)
.use(languageDetector)
.init({
    ...initOpts,

    resources: testResources,
    // Load additional resource bundles from backend.
    partialBundledLanguages: true,
    // Use empty string for missing keys on the front-end.
    parseMissingKeyHandler: () => '',
    // parseMissingKeyHandler: (key) => `[Missing: ${key}]`,
    // debug: true,
});

const i18n = new VueI18Next(i18next);

export default i18n;

export { i18next };
