
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        extends: BasePage,

        data () {
            return {
                pageName: 'error',
            };
        },
        i18nOptions: { namespaces: ['error'] },
    };
